import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_comp_table = _resolveComponent("comp-table")!
  const _component_elem_form = _resolveComponent("elem-form")!
  const _component_comp_modal = _resolveComponent("comp-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_comp_table, {
      ref: "comp_table",
      title: _ctx.type === 'manage' ? '订单管理' : '优惠申请审核',
      entity: "BusinessOrder",
      columns: _ctx.columns,
      "table-api": "WEB://BusinessOrder/FindAllToPage?type=VEHICLE"
    }, {
      search: _withCtx((evt) => [
        _createVNode(_component_Input, {
          modelValue: evt.search.orderNumber,
          "onUpdate:modelValue": ($event: any) => ((evt.search.orderNumber) = $event),
          placeholder: "订单编号"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      "row-operate": _withCtx((params) => [
        (params.row.paymentStatus === 0 && _ctx.isDiscountApply(params.row.discounts))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "info",
              style: {"margin":"3px"},
              onClick: ($event: any) => (_ctx.onDisplayApplyForm(params.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("申请优惠")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : (params.row.discounts?.length && params.row.discounts[params.row.discounts.length - 1].approvalStatus === '0')
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                type: "warning",
                style: {"margin":"3px"},
                onClick: ($event: any) => (_ctx.onCancelApply(params.row.uuid))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 取消申请 ")
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "columns"]),
    _createVNode(_component_comp_modal, {
      title: "申请优惠",
      ref: "apply_modal",
      height: "auto",
      "on-success": _ctx.onSubmitApply
    }, {
      default: _withCtx(() => [
        _createVNode(_component_elem_form, {
          ref: "apply_form",
          structure: _ctx.structure
        }, null, 8, ["structure"])
      ]),
      _: 1
    }, 8, ["on-success"])
  ]))
}