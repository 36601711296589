
import CompTable from "@/components/comp-table.vue"
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"

import Component, { ComponentMethods } from "@/module/component/component"
import Request from "@/module/request/request"

import Employee from "@/entity/employee"
import Utils from "@/module/utils/utils"

class OrderTablePage extends ComponentMethods implements ComponentEntity {
    components = {
        CompTable,
        CompModal,
        ElemForm,
    }

    watch = {
        $route(to: obj) {
            if (to.path === "/order") {
                this.type = Utils.getParam("type") || "manage"
                this.$refs.comp_table.reset()
            }
        },
    }

    activated() {
        this.$refs.comp_table.reset()
    }

    data() {
        return {
            columns: [
                {
                    title: "订单编号",
                    key: "orderNumber",
                },
                {
                    title: "订单名称",
                    key: "name",
                },
                {
                    title: "支付状态",
                    render: (h: Function, params: obj) => {
                        return ["未支付", "已支付", "已取消", "支付失败"][params.row.paymentStatus] || "未知"
                    },
                },
                {
                    title: "总金额",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.totalPrice ? "0.00" : (params.row.totalPrice / 100).toFixed(2))
                    },
                },
                {
                    title: "优惠金额",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.discountPrice ? "0.00" : (params.row.discountPrice / 100).toFixed(2))
                    },
                },
                {
                    title: "实际支付金额",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.price ? "0.00" : (params.row.price / 100).toFixed(2))
                    },
                },
                {
                    title: "用户",
                    key: "user.username",
                },
                {
                    title: "创建日期",
                    key: "createdDate",
                },
                {
                    title: "申请优惠金额",
                    render: (h: Function, params: obj) => {
                        const discounts = params.row.discounts

                        if (!discounts?.length) return "-"

                        const discount = discounts[discounts.length - 1]

                        return discount.discountAmount ? "￥" + (discount.discountAmount / 100).toFixed(2) : "-"
                    },
                },
                {
                    title: "申请状态",
                    render: (h: Function, params: obj) => {
                        const discounts = params.row.discounts

                        if (!discounts?.length) return "-"

                        const discount = discounts[discounts.length - 1]

                        return Utils.isExist(discount.approvalStatus) ? ["待审核", "已通过", "已拒绝", "取消申请"][discount.approvalStatus] || "未知" : "-"
                    },
                },
                {
                    title: "申请备注",
                    key: "applyRemark",
                    render: (h: Function, params: obj) => {
                        const discounts = params.row.discounts

                        if (!discounts?.length) return "-"

                        const discount = discounts[discounts.length - 1]

                        return discount.applyRemark || "-"
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 200,
                    slot: "row-operate",
                },
            ],

            structure: [
                {
                    field: "applyDiscount",
                    name: "applyDiscount",
                    title: "优惠金额",
                    type: "Price",
                    required: true,
                },
                {
                    field: "applyRemark",
                    name: "applyRemark",
                    title: "备注",
                    type: "Input",
                    required: false,
                },
            ],

            employeeUuid: Employee.getUuid(),

            type: Utils.getParam("type") || "manage",
        }
    }

    isAdmin() {
        return Employee.isAdmin()
    }

    onDisplayApplyForm(item: obj) {
        this.ac_apply = item
        this.$refs.apply_modal.display()
    }

    /**
     * 提交申请
     */
    async onSubmitApply() {
        const data = await this.$refs.apply_form.getFormData()

        Request.post(
            "WEB://Discount/Save",
            {
                ...data,
                order: this.ac_apply.uuid,
            },
            {
                success: "申请成功，请耐心等待审核！",
            }
        ).then(() => {
            // 关闭弹窗
            this.$refs.apply_modal.close()
            // 刷新表格
            this.$refs.comp_table.refresh()
        })
    }

    /**
     * 取消申请
     */
    onCancelApply(uuid: string) {
        Request.post(
            "WEB://BusinessOrder/CancelApply",
            {
                uuid: uuid,
            },
            {
                success: "取消成功",
            }
        ).then(() => {
            // 刷新表格
            this.$refs.comp_table.refresh()
        })
    }

    /**
     * 是否可以申请优惠
     */
    isDiscountApply(discounts: obj[]) {
        if (!discounts || discounts.length === 0) {
            return true
        }

        return discounts.every((item: obj) => {
            return item.approvalStatus === "3"
        })
    }
}

export default Component.build(new OrderTablePage())
